/* this file is transformed by vux-loader */
/* eslint-disable */
import { format } from "../../assets/app";
export default {
  props: {
    headTit: {
      type: String,
      default: ""
    },
    tit: {
      type: String,
      default: ""
    },
    oneft: {
      type: String,
      default: "/index"
    },
    searchVal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchShow: this.searchVal,
      showSearch: false,
      showStar: false,
      showEnd: false,
      minDate: new Date(1970, 0, 1),
      currentDate: new Date(),
      data: {
        sbbh: "",
        sbmc: "",
        jhks: "",
        jhjs: ""
      }
    };
  },
  methods: {
    onClickLeft() {
      // this.$router.push(this.oneft);
      this.$router.go(-1);
    },
    onSearch() {
      this.showSearch = true;
    },
    onConfirmJhks(timestamp) {
      this.data.jhks = format(timestamp);
      this.showStar = false;
    },
    onConfirmJhjs(timestamp) {
      this.data.jhjs = format(timestamp);
      this.showEnd = false;
    },
    onEmpty() {
      this.data = {};
      this.$emit("qjjcLists", "notSearch");
      this.showSearch = false;
    },
    onDifine() {
      this.$emit("qjjcLists", this.data);
      this.showSearch = false;
    }
  }
};