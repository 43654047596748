/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, format } from "@/assets/app.js";
export default {
  name: "lcfqRylz",
  components: {
    LCFQheader
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "人员离职",
      checkbox: false,
      showDataRZ: false,
      showDataHTDQ: false,
      showDataSQ: false,
      showDataXZLZ: false,
      minDate: new Date(1961, 0, 1),
      currentDate: new Date(),
      userID: "",
      solId: "",
      actDefId: "",
      data: {
        ks: "",
        gw: "",
        rzsj: "",
        zw: ""
      },
      xmId: ""
    };
  },
  created() {
    this.cgORxj();
    this.initBySolInstId();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
      });
    },
    async cgORxj() {
      let busad = this.$route.params.busad;
      if (busad != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/customizeProc/resignationAppForm/resignationAppForm/getJson.do?ids=" + busad;
        this.$ajax.post(url).then(res => {
          this.data = res.data;
          this.data.rzsj = format(res.data.rzsj);
          this.data.htdqr = format(res.data.htdqr);
          this.data.hzlzrq = format(res.data.hzlzrq);
          this.data.sqlzrq = format(res.data.sqlzrq);
        });
      } else {
        this.getUser();
      }
    },
    //获取用户信息
    getUser() {
      var userID = RxUtil.getCache("userID");
      this.data.xm = RxUtil.getCache("userName");
      // this.data.ks = RxUtil.getCache("mainGroupName");
      var xmId = {
        XMID: userID
      };
      var paramsId = new FormData();
      paramsId.append("params", JSON.stringify(xmId));
      var urlGetUser = _baseUrl + "/customizeProc/annualLeaveForm/annualLeaveForm/getUser.do?ids=";
      this.$ajax.post(urlGetUser, paramsId).then(resUser => {
        this.xmId = resUser.data.userId;
        let UserId = {
          PARTY2_: resUser.data.identityId
        };
        let UserIdString = JSON.stringify(UserId);
        let fromDataUserId = new FormData();
        fromDataUserId.append("params", UserIdString);
        let urlks = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqbm.do";
        this.$ajax.post(urlks, fromDataUserId).then(resks => {
          this.data.ks = resks.data.data[0].NAME_;
          // this.data.gw = resks.data.data[1].NAME_;
          if (resks.data.data[0].NAME_ == resks.data.data[1].NAME_) {
            let num = resks.data.data.length - 1;
            this.data.gw = resks.data.data[2].NAME_;
            this.data.zw = resks.data.data[3].NAME_ + resks.data.data[num].NAME_;
          } else {
            this.data.gw = resks.data.data[1].NAME_;
            this.data.zw = resks.data.data[2].NAME_;
          }
        });
        let UserIdXM = {
          USER_ID_: resUser.data.identityId
        };
        let UserIdStringXM = JSON.stringify(UserIdXM);
        let fromUserIdXM = new FormData();
        fromUserIdXM.append("params", UserIdStringXM);
        let urlhqxm = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqxm.do";
        this.$ajax.post(urlhqxm, fromUserIdXM).then(reshqxm => {
          let rzsj = Number(reshqxm.data.data[0].ENTRY_TIME_);
          this.data.rzsj = format(rzsj);
        });
      });
    },
    dayTime(dataTime) {
      let year = dataTime.getFullYear();
      const month = (dataTime.getMonth() + 1).toString().padStart(2, "0");
      const date = dataTime.getDate().toString().padStart(2, "0") + " ";
      const hours = dataTime.getHours() + ":";
      const minutes = dataTime.getMinutes();
      return year + "-" + month + "-" + date;
    },
    onConfirmRZ(timestamp) {
      this.data.rzsj = this.dayTime(timestamp);
      this.showDataRZ = false;
    },
    onConfirmHTDQ(timestamp) {
      this.data.htdqr = this.dayTime(timestamp);
      this.showDataHTDQ = false;
    },
    onConfirmSQ(timestamp) {
      this.data.sqlzrq = this.dayTime(timestamp);
      this.showDataSQ = false;
    },
    onConfirmXZLZ(timestamp) {
      this.data.hzlzrq = this.dayTime(timestamp);
      this.showDataXZLZ = false;
    },
    datas() {
      var jsonDataObj = {
        persid: "",
        xmId: this.xmId,
        xm: this.data.xm,
        ks: this.data.ks,
        gw: this.data.gw,
        rzsj: this.data.rzsj,
        htdqr: this.data.htdqr,
        zw: this.data.zw,
        lzlb: this.data.lzlb,
        sqlzrq: this.data.sqlzrq,
        hzlzrq: this.data.hzlzrq,
        lzyy: this.data.lzyy,
        id: this.xmId,
        lzlb_name: this.data.lzlb
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("jsonData", jsonData);
      return params;
    },
    onSubmit() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};